import React from 'react'

const OnlineOrders = () => {
  return (
    <article style={{textAlign: 'center'}}>

    </article>
  )
}

export default OnlineOrders
